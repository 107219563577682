<template>
  <div>
    <hs-modal :id="idModal" :title="title" size="lg" bodyClass="px-sm-4 px-md-5 py-3" hide-footer>
      <b-img fluid lazy :alt="title" :src="require(`@/assets/images/student_area/${imgName}.svg`)"></b-img>
    </hs-modal>
  </div>
</template>

<script>
import { hsModal } from '@/components';

export default {
  components: {
    hsModal,
  },
  props: {
    idModal: String,
    title: String,
    imgName: String,
  },
};
</script>

<style lang="scss" scoped></style>
